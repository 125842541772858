/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'swiss': {
    width: 32,
    height: 33,
    viewBox: '0 0 32 33',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M5.57 10.356v4.92h6.572v-4.92H5.57zm0 5.92h6.572v4.638H5.57v-4.638zm7.572 0v4.638h6.428v-4.638h-6.428zm7.428 0v4.638h5.857v-4.638H20.57zm-1 5.638h-6.428v4.637h6.428v-4.637zm1 4.637v-4.637h5.857v4.637H20.57zm-1 1h-14a1 1 0 01-1-1V6.001a1 1 0 011-1h20.857a1 1 0 011 1v20.55a1 1 0 01-1 1H19.57zm1-12.275v-4.92h5.857v4.92H20.57zm-1-4.92v4.92h-6.428v-4.92h6.428zm-14 11.558h6.572v4.637H5.57v-4.637z" _fill="#898B9B"/>'
  }
})
